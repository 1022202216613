body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111111;
}
html {
  background-color: #111111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url(../../static/media/bg2.b6d543d0.jpg) center;
}
.banner1 .bg1 {
  background: url(../../static/media/bg3.98ec23e3.jpg) center;
}
.banner1 .bg2 {
  background: url(../../static/media/bg1.0195a726.jpg) center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
}
.banner1 .banner1-logo-img {
  width: 150px;
  height: 80px;
  left: 30px;
  top: 25px;
  margin: auto;
  display: inline-block;
  position: absolute;
}
.banner1 .banner1-title {
  position: relative;
  margin-left: 37px;
  margin-top: 137px;
  display: inline-block;
  font-size: 32px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 44px;
}
.banner1 .banner1-content {
  position: relative;
  margin-left: 37px;
  margin-top: 14px;
  margin-bottom: 20px;
  word-wrap: break-word;
  max-width: 515px;
  height: 120px;
  font-size: 26px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
}
.banner1 .banner1-addon {
  width: 150px;
  height: auto;
  right: 25px;
  top: 40px;
  position: absolute;
  font-family: AvenirNext-Bold, AvenirNext;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.banner1 .banner1-addon .addon-save-m {
  width: 97px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 12px;
  text-align: right;
  -webkit-transform: scale(0.83, 0.83);
          transform: scale(0.83, 0.83);
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}
.banner1 .banner1-addon .addon-mcl {
  width: 97px;
  font-size: 12px;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 16px;
  text-align: right;
  padding: 2px 0;
}
.banner1 .banner1-addon .addon-save-w {
  width: 97px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 12px;
  text-align: right;
  -webkit-transform: scale(0.83, 0.83);
          transform: scale(0.83, 0.83);
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
}
.banner1 .banner1-addon .addon-company {
  width: 360px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 12px;
  text-align: left;
  -webkit-transform: scale(0.83, 0.83) rotate(90deg);
          transform: scale(0.83, 0.83) rotate(90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  position: absolute;
  right: -360px;
  top: 184px;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 100%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh);
  max-height: 960px;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url(../../static/media/bg2.b6d543d0.jpg) center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  background: rgba(0, 0, 0, 0.35);
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  max-width: 1200px;
  margin: auto;
  top: 20%;
}
.banner2 .banner2-logo-img {
  width: 266px;
  height: 133px;
  left: 0;
  top: -200px;
  margin: auto;
  display: inline-block;
  position: absolute;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 1025px;
  text-align: left;
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 54px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 66px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: 7px auto 20px;
  font-weight: lighter;
  font-size: 42px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 60px;
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1400px) and (min-width: 816px) {
  .banner2-page {
    top: 20%;
  }
  .banner2 .banner2-logo-img {
    width: 200px;
    height: 100px;
    left: 0;
    top: -150px;
    margin: auto;
    display: inline-block;
    position: absolute;
  }
  .banner2-text-wrapper {
    width: 550px;
  }
  .banner2-text-wrapper .banner2-title {
    font-size: 36px;
  }
  .banner2-text-wrapper .banner2-content {
    font-size: 28px;
    line-height: 42px;
  }
}
@media screen and (max-width: 815px) {
  .banner2-page {
    top: 20%;
  }
  .banner2 .banner2-logo-img {
    width: 200px;
    height: 100px;
    left: 0;
    top: -150px;
    margin: auto;
    display: inline-block;
    position: absolute;
  }
  .banner2-text-wrapper {
    width: 550px;
  }
  .banner2-text-wrapper .banner2-title {
    font-size: 28px;
  }
  .banner2-text-wrapper .banner2-content {
    font-size: 22px;
    line-height: 30px;
    text-align: left;
  }
}
@media screen and (max-width: 414px) {
  .banner2 {
    max-height: 660px;
  }
  .banner2 .banner2-logo-img {
    left: 55px;
  }
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
}
.content1-wrapper {
  height: 360px;
  background-color: #fff;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
  box-sizing: border-box;
  border-radius: 18px;
  box-shadow: 0 2px 36px rgba(0, 0, 0, 0.36);
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-pb50 {
  padding-bottom: 50px;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 100px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
  .content1-pb50 {
    height: 650px!important;
  }
}
.content2-wrapper {
  height: 360px;
  background-color: #fff;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
  border-radius: 18px;
  box-shadow: 0 2px 36px rgba(0, 0, 0, 0.36);
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 34px;
  font-weight: normal;
  color: #404040;
  margin-top: 100px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.footer1-wrapper {
  background: #111111;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #fff;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #fff;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 88px;
  text-align: left;
  line-height: 88px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding-left: 32px;
}
.footer1-wrapper .copyright-wrapper .copyright a {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
    padding-left: 24px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Footer1_0 .ant-col > div > .footer-contact_title_css {
  display: flex;
  justify-content: center;
}
#Footer1_0 .ant-col > div > .footer-contact_editor_css {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
#Footer1_0 .ant-col > div > .footer-contact_editor_css > div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
#Footer1_0 .ant-row > .ant-col > .footer-contact_editor_css {
  display: flex;
  justify-content: flex-start;
}

